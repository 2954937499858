.queryFirst{

	.page-header{

		.img{
			width: 45%;
			max-width: 183px;
			min-width: 165px;
		}
	}

	&__block{
		padding: 30px 15px;

		.inner{
			margin: 0 auto;
			padding: 0;
			max-width: 1100px;
		}
	}

	&__anchor{
		margin: 30px auto 38px;
	}

	//------------------------------------------
	&__step{

		&__intro{
			padding: 40px 15px 23px;
		}

		&__anchor{
			margin: 0 auto;
			padding: 0 15px 45px;
			width: 100%;
			max-width: 330px;

			.flexItem{
				@media screen and (max-width: 991px) {

					&:not(:first-child){
						margin-top: 47px;
						position: relative;

						&::before{
							content: "";
							display: block;
							width: 30px;
							height: 20px;
							background-image: url(../images/common/icon_arw_down.svg);
							background-repeat: no-repeat;
							background-position: 0 0;
							background-size: contain;
							position: absolute;
							left: 50%;
							top: 0;
							transform: translate(-50%, -35px);
						}
					}
				}
			}

			.anchorBtn{
				display: flex;
				text-decoration: none;
				background-color: #0072BC;
				border: 1px solid #0072BC;
				border-radius: 5px;
				overflow: hidden;
				position: relative;
				transition: all 0.3s ease;

				&::after{
					content: "";
					display: block;
					width: 20px;
					height: 20px;
					background-image: url(../images/common/icon_arw_btn3.svg);
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: contain;
					position: absolute;
					right: 15px;
					top: 50%;
					transition: all 0.3s ease;
					transform: translate(0, -50%) rotate(90deg);
				}

				.step{
					align-items: stretch;
					padding: 10px;
					width: 58px;
					color: #FFF;
					font-size: 13px;
					font-weight: 500;
					text-align: center;
					line-height: 1;

					.flex-align-center{
						display: flex;
						align-items: center;
						height: 100%;

						span{
							margin: 0 auto;
						}
					}

					.num{
						display: block;
						width: 100%;
						color: #FFF;
						font-size: 25px;
						font-weight: 700;
						font-style: normal;
						line-height: 1;
					}
				}

				.ttl{
					display: flex;
					align-items: center;
					padding: 10px;
					width: calc( 100% - 58px);
					font-size: 15px;
					font-weight:500;
					line-height: 1.55;
					background-color: #FFF;
				}
			}
		}

		&__block{
			border-top: 1px solid #C0DBEC;
			border-bottom: 1px solid #C0DBEC;
			position: relative;

			&.step02, &.step03, &.step04, &.step05, &.step06{
				margin-top: 50px;
				position: relative;

				&::before{
					content: "";
					display: block;
					width: 30px;
					height: 20px;
					background-image: url(../images/common/icon_arw_down.svg);
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: contain;
					position: absolute;
					left: 50%;
					top: 0;
					transform: translate(-50%, -35px);
				}
			}

			&.step06{
				margin-bottom: 50px;
			}
		}

		&__head{

			.step__title{
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;

				&__num{
					align-items: stretch;
					padding: 10px;
					color: #0072BC;
					font-size: 13px;
					font-weight: 500;
					text-align: center;
					line-height: 1;
					position: relative;

					&::after{
						content: "";
						display: block;
						width: 2px;
						height: 60%;
						border-left: 2px solid #0072BC;
						position: absolute;
						right: 0;
						top: 50%;
						transform: translate(0, -50%);
					}

					.flex-align-center{
						display: flex;
						align-items: center;
						height: 100%;
					}

					.num{
						display: block;
						margin-top: 5px;
						width: 100%;
						color: #0072BC;
						font-size: 25px;
						font-weight: 700;
						font-style: normal;
						line-height: 1;
					}
				}

				&__txt{
					padding: 8px;

					.ttl{
						font-size: 18px;
						font-weight: 700;
						letter-spacing: 0.05em;;
					}
				}
			}

			.step__leadTxt{
				margin-top: 10px;
			}
		}

		&__body{

			ol{

				& > li{
					margin-left: 1.5em;
					list-style: decimal outside;
				}
			}

			ul{
				margin-top: 7px;
				padding-left: 24px;

				& > li{
					position: relative;

					&::before{
						content: "";
						display: block;
						width: 8px;
						height: 8px;
						background-color: #C0DBEC;
						border-radius: 50%;
						position: absolute;
						left: 0;
						top: 0;
						transform: translate(-24px, 10px);
					}
				}
			}
		}
	}

	//------------------------------------------
	&__select{

		// queryFirst__select__buttons
		&__buttons{
			margin-top: 20px;

			.flexBox{
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin: 0 -7px;
			}

			.flexItem{
				margin: 7px 0;
				padding: 0 7px;
				width: 50%;
			}

			.selectBtn{
				padding: 9px 35px 9px 10px;
				width: 100%;
				height: 100%;
				color: #FFF;
				font-size: 13px;
				font-weight: 500;
				text-align: center;
				line-height: 1.54;
				letter-spacing: 0;
				background-color: #0072BC;
				border: 1px solid #0072BC;
				border-radius: 5px;
				cursor: pointer;

				&.on{
					color: #0072BC;
					background-color: #FFF;
					pointer-events: none;
				}

				&:hover{
					color: #0072BC;
					background-color: #F1F6F9;
				}

				@media screen and (max-width: 767px) {
					position: relative;

					&::after{
						content: "";
						display: block;
						width: 18px;
						height: 18px;
						background-image: url(../images/common/icon_arw_btn2.svg);
						background-repeat: no-repeat;
						background-position: 0 0;
						background-size: contain;
						position: absolute;
						right: 5px;
						top: 50%;
						transform: translate(0, -50%) rotate(90deg);
					}

					&.on{
						&::after{
							background-image: url(../images/common/icon_arw_btn3.svg);
						}
					}
				}
			}
		}

		// queryFirst__select__contents
		&__contents{

			&__box{
				display: none;
				padding: 30px 0;

				&.open{
					display: block;
				}

				.box__title{
					margin-bottom: 20px;
					font-size: 16px;
					font-weight: 700;
					text-align: center;
					line-height: 1.875;
					letter-spacing: 0;
				}

				h4{
					margin: 40px 0 15px;
					font-size: 15px;
					font-weight: 700;
				}

				ol{
					margin: 15px 0;
				}

				p.link_2_1{
					margin: 25px 0 15px;

					a{
						margin: 0 auto;
						width: 100%;
						height: 79px;
						max-width: 345px;
						text-align: center;
						line-height: 1.4;
					}
				}

				p.link_2_2{
					margin: 25px 0 15px;

					a{
						margin: 0 auto;
						width: 100%;
						height: 58px;
						max-width: 345px;
						text-align: center;
						line-height: 1.4;
					}
				}

				p.contactBtn{
					margin: 25px 0 15px;
				}

				p.telTxt{

					a{
						margin-right: 5px;
						font-size: 18px;
						font-weight: 700;
					}
				}

				p.attent{
					margin: 0;

					small{
						display: block;
						font-size: 13px;
						line-height: 1.9;
					}
				}
			}
		}
	}

	//------------------------------------------
	&__tbl{
		margin-top: 20px;
		text-align: left;
		border-bottom: 1px solid #C0DBEC;

		th, td{
			font-size: 13px;
			font-weight: 400;
			border-top: 1px solid #C0DBEC;

			ul{
				margin-top: 0;
				padding-left: 10px;

				& > li{

					&::before{
						width: 4px;
						height: 4px;
						transform: translate(-10px, 10px);
					}
				}
			}
		}

		th{
			padding: 12px 12px 12px 10px;
			width: 130px;
			background-color: #F1F6F9;
		}

		td{
			padding: 12px 0 12px 10px;
			width: calc( 100% - 130px );
			vertical-align: top;

			p.attent{
				padding-left: 10px;

				small{
					display: block;
					font-size: 13px;
					line-height: 1.9;
					position: relative;

					&::before{
						content: "※";
						position: absolute;
						left: -1.2em;
						top: 0;
					}
				}
			}
		}
	}

	//------------------------------------------
	&__contents{

		&__box{
			margin: 30px 0;

			&:nth-of-type(1){
				margin-top: 0;
			}

			.box__title{
				margin-top: 25px;
				margin-bottom: 15px;
				font-size: 15px;
				font-weight: 700;
			}

			figure{
				margin: 20px auto;

				img{
					display: block;
				}
			}

			.link_faq{
				margin-top: 10px;
				text-align: right;

				a{
					padding-left: 30px;
					font-size: 14px;
					font-weight: 700;
					text-decoration: none;
					position: relative;

					&::before{
						content: "";
						display: block;
						width: 20px;
						height: 20px;
						background-image: url(../images/common/icon_arw_btn3.svg);
						background-repeat: no-repeat;
						background-position: 0 0;
						background-size: contain;
						position: absolute;
						left: 0;
						top: 50%;
						transition: all 0.3s ease;
						transform: translateY(-50%) ;
					}
				}
			}
		}
	}

	//------------------------------------------
	&__finish__message{
		margin-top: 30px;
		text-align: center;

		p{
			padding: 18px 4px;
			font-size: 18px;
			font-weight: 700;
			line-height: 1;
			background-color: #F1F6F9;
		}
	}

	//------------------------------------------
	&__pageLink{
		padding: 40px 20px;
		background-color: #0072BC;

		h2{
			color: #fff;
			font-size: 16px;
			font-weight: 700;
			text-align: center;
		}

		.cont{
			margin: 15px auto 0;
			max-width: 345px;
		}

		a{
			display: flex;
			color: #333;
			text-decoration: none;
			border-radius: 6px;
			overflow: hidden;
			position: relative;

			&::after{
				content: "";
				display: block;
				width: 20px;
				height: 14px;
				background-image: url(../images/common/icon_blank_blue.svg);
				background-repeat: no-repeat;
				background-position: 0 0;
				background-size: contain;
				position: absolute;
				right: 15px;
				top: 50%;
				transform: translate(0, -50%);
			}
		}

		.img{
			display: flex;
			align-items: center;
			width: 21.5%;
			background: #00cbf5;
			background: -moz-linear-gradient(left,  #00cbf5 0%, #00cede 100%);
			background: -webkit-linear-gradient(left,  #00cbf5 0%,#00cede 100%);
			background: linear-gradient(to right,  #00cbf5 0%,#00cede 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00cbf5', endColorstr='#00cede',GradientType=1 );


			img{
				display: block;
			}
		}

		.textarea{
			padding: 13px 35px 13px 15px;
			width: 78.5%;
			background-color: #fff;
			@media screen and (min-width: 385px) {
				padding: 15px 45px 15px 15px;
			}

			.t1{
				font-size: 14px;
				font-weight: 500;
			}

			.ttl{
				font-size: 18px;
				font-weight: 700;
			}

			.t2{
				font-size: 13px;
			}
		}
	}

	//------------------------------------------
	.toolBtn{
		margin: 20px 0 15px;

		a{
			display: block;
			margin: 0 auto;
			width: 100%;
			height: 60px;
			max-width: 690px;
			text-indent: -9999px;
			overflow: hidden;
			background-image: url(../images/query/first/tool_btn_sp.png);
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: contain;
		}

		& + .attent{
			text-align: center;
		}
	}

	&__nextstep{
		margin: 0;
		padding: 0 25px 0 0;
		width: 53px;
		height: auto;
		font-size: 12px;
		border: 0;
		position: absolute;
		right: 15px;
		top: 100%;
		transform: translateY(10px);

		&::after{
			width: 18px;
			height: 18px;
			right: 0;
		}

		&:hover{
			color: inherit;
			background: none;
			opacity: 0.7;
		}
	}
}
@media screen and (min-width: 768px) {
	.queryFirst{

		.page-header{

			.img{
				width: 100%;
				max-width: 365px;
			}
		}

		&__pageLink{

			h2{
				font-size: 18px;
				letter-spacing: 0;
			}

			.cont{
				margin: 30px auto 0;
				max-width: 900px;
			}

			a{
			}

			.img{
				width: 38.6%;
			}

			.textarea{
				display: flex;
				flex-wrap: wrap;
				width: 61.4%;

				.t1{
					width: 40%;
					font-size: 14px;
				}

				.ttl{
					width: 60%;
					font-size: 22px;
					font-weight: 700;
				}

				.t2{
					width: 100%;
					font-size: 14px;
				}
			}
		}
	}
}
@media screen and (min-width: 992px) {
	.queryFirst{

		//------------------------------------------
		&__block{
			padding: 80px 15px;

			.inner{
				margin: 0 auto;
				padding: 0;
			}

			ul{
				margin-top: 18px;
			}
		}

		//------------------------------------------
		&__step{

			&__intro{
				padding: 75px 15px 60px;
				text-align: center;
			}

			&__anchor{
				padding: 0 15px 50px;
				max-width: 100%;

				.flexBox{
					display: flex;
					flex-wrap: wrap;
				}

				.flexItem{
					margin: 15px 0 !important;
					width: 33.3333%;

					&:not(:last-child){
						position: relative;

						&::after{
							content: "";
							display: block;
							width: 30px;
							height: 20px;
							background-image: url(../images/common/icon_arw_down.svg);
							background-repeat: no-repeat;
							background-position: 0 0;
							background-size: contain;
							position: absolute;
							right: 4px;
							top: 50%;
							transform: translate(0, -50%) rotate(-90deg);
						}
					}
				}

				.anchorBtn{
					margin-right: 42px;
					height: 100%;

					&::after{
						width: 24px;
						height: 24px;
					}

					.step{
						width: 74px;
						font-size: 15px;

						.num{
							font-size: 20px;
						}
					}

					.ttl{
						padding: 8px 14px;
						width: calc(100% - 74px);
						font-size: 14px;
					}

					&:hover{
						opacity: 1;
						background-color: #008EEB;
						border: 1px solid #008EEB;

						&::after{
							margin-top: 10px;
						}
					}
				}
			}

			&__block{
				padding: 20px 15px;
				border: 0;

				& > .inner{
					padding: 40px 100px 50px;
					border: 1px solid #C0DBEC;
				}

				&.step01{
					.queryFirst__select__buttons .flexItem{
						width: 25%;
					}
				}

				&.step02, &.step03, &.step04, &.step05, &.step06{

					&::before{
						width: 50px;
						height: 33px;
					}
				}
			}

			&__head{}

			&__body{

				ul{

					& > li{

						&::before{
							transform: translate(-24px, 16px);
						}
					}
				}
			}
		}

		//------------------------------------------
		&__select{

			// queryFirst__select__buttons
			&__buttons{
				border-bottom: 1px solid #0072BC;

				.flexBox{
					margin: 0 -15px;
				}

				.flexItem{
					margin: 0 0 -1px;
					padding: 0 15px;
					width: 33.333%;
				}

				.selectBtn{
					margin-bottom: -1px;
					padding: 10px;
					font-weight: 700;
					border-radius: 6px 6px 0 0;

					&.on{
						border-bottom: 1px solid #FFF;
					}
				}
			}

			// queryFirst__select__contents
			&__contents{

				&__box{

					p.link_2_1{

						a{
							width: 100%;
							height: 108px;
							max-width: 625px;
						}

						& + .attent{
							margin: 0 auto;
							max-width: 625px;
						}
					}

					p.link_2_2{
						margin: 25px 0 15px;

						a{
							width: 100%;
							height: 72px;
							max-width: 625px;
						}
					}
				}
			}
		}

		//------------------------------------------
		&__tbl{
			margin-top: 40px;

			th, td{
				padding: 10px;
				font-size: 17px;

				ul{
					margin-top: 0;
					padding-left: 18px;

					& > li{

						&::before{
							width: 8px;
							height: 8px;
							transform: translate(-18px, 16px);
						}
					}
				}
			}

			th{
				padding: 20px 0 20px 30px;
				width: 340px;
				background-color: #F1F6F9;
			}

			td{
				padding: 20px 0 20px 40px;
				width: calc( 100% - 340px );
			}
		}

		//------------------------------------------
		&__pageLink{
			padding: 60px 20px;

			a{

				&::after{
					width: 26px;
					height: 18px;
					right: 40px;
				}
			}

			.textarea{
				padding: 40px 67px 40px 60px;

				.t1{
					width: 45%;
					font-size: 18px;
				}

				.ttl{
					width: 55%;
					font-size: 24px;
					font-weight: 700;
				}

				.t2{
					margin-top: 5px;
					width: 94%;
					font-size: 16px;
				}
			}
		}

		//------------------------------------------
		.toolBtn{
			margin: 20px 0 15px;

			a{
				width: 100%;
				height: 90px;
				max-width: 408px;
				background-image: url(../images/query/first/tool_btn.png);
				transition: all 0.3s ease;
				position: relative;

				&::after{
					content: "";
					display: block;
					width: 24px;
					height: 24px;
					background-image: url(../images/common/icon_arw_btn2.svg);
					background-repeat: no-repeat;
					background-position: 0 0;
					background-size: contain;
					position: absolute;
					right: 30px;
					top: 50%;
					transition: all 0.3s ease;
					transform: translateY(-50%);
				}

				&:hover{
					background-image: url(../images/query/first/tool_btn_on.png);
					opacity: 1;

					&::after{
						right: 25px;
					}
				}
			}
		}
	}
}

@media screen and (min-width: 1100px) {
	.queryFirst{

		//------------------------------------------
		&__step{

			&__anchor{

				.anchorBtn{

					.ttl{
						font-size: 18px;
					}
				}
			}

			&__block{

				&.step06{
					margin-bottom: 80px;
				}
			}

			&__head{

				.step__title__num{
					padding: 10px 20px;

					.flex-align-center{

					}
				}

				.step__title__txt{
					padding: 10px 20px;

					.ttl{
						font-size: 24px;
					}
				}

				.step__leadTxt{
					margin-top: 30px;
				}
			}

			&__body{
				margin-top: 40px;

				ul{

					& > li{
						line-height: 2.07;

						&::before{
							transform: translate(-24px, 14px);
						}
					}
				}
			}
		}

		//------------------------------------------
		&__select{

			// queryFirst__select__buttons
			&__buttons{

				.selectBtn{
					padding: 20px 5px;
					font-size: 16px;
				}
			}

			// queryFirst__select__contents
			&__contents{

				&__box{
					padding: 54px 0;

					.box__title{
						font-size: 21px;
						letter-spacing: 0.05em;
					}

					h4{
						font-size: 18px;
					}

					p.telTxt {

						a{
							font-size: 20px;
						}
					}

				}
			}

		}

		//------------------------------------------
		&__tbl{
			margin-top: 35px;

			th, td{
				padding: 15px 0 15px 30px;
			}

			th{
				padding: 15px 0 15px 30px;
			}

			td{
				padding: 15px 0 15px 40px;
			}
		}

		//------------------------------------------
		&__contents{

			&__box{

				.box__title{
					font-size: 18px;
				}

				figure{
					margin: 40px auto;

					&.step_1_1{
						max-width: 408px;
					}

					&.step_3_1{
						max-width: 750px;
					}

					&.step_4_1{
						max-width: 750px;
					}

					&.step_4_2{
						max-width: 750px;
					}
				}

				.link_faq {

					a{
						padding-left: 35px;
						font-size: 16px;

						&::before{
							width: 24px;
							height: 24px;
						}
					}
				}
			}
		}

		//------------------------------------------
		.toolBtn{
			margin: 48px 0 15px;
		}

		//------------------------------------------
		&__finish__message{
			margin-top: 60px;

			p{
				padding: 20px 4px;
				font-size: 24px;
			}
		}

	}
}






//-----------------------------------------
.queryFirst__attention{
	margin-top: 30px;

	h3{
		padding-left: 7px;
		color: #0072BC;
		font-size: 17px;
		line-height: 24px;
		border-left: 5px solid #0072BC;
	}
}
@media screen and (min-width: 1100px) {
	.queryFirst__attention{
		margin-top: 45px;

		h3{
			padding-left: 18px;
			font-size: 20px;
			letter-spacing: 0.05em;
			border-left: 7px solid #0072BC;
		}

		ul{
			margin-top: 14px;

			li{
				margin-top: 3px;
				font-size: 16px;
				line-height: 2.25;
			}
		}
	}
}


//-----------------------------------------
.queryFirst__send{
	margin-top: 50px;

	h3{
		padding-left: 20px;
		color: #0072BC;
		line-height: 24px;
		border-left: 7px solid #0072BC;
	}

}